<template>
	<v-container fluid>
		<v-card>
			<v-card-text>
				<crud
					:model="'Novelty'"
					create-permission="Agregar Novedades"
					edit-permission="Editar Novedades"
					delete-permission="Eliminar Novedades"
					resource="novelties"
					load="worker,concept,cliente"
					:single-label="'novelty' | trans"
					show-clone
					bulk-actions
					@add-item="checkItem"
					@edit-item="checkItem"
				>
					<template v-slot:form="{ headers, item, cancelAction, saveAction, getData }">
						<novelty-editor
							:headeers="headers"
							:value="item"
							:save-action="saveAction"
							@cancel="cancelAction"
							@saved="getData"
						></novelty-editor>
					</template>
					<template v-slot:extra-items-menu="{ item }">
						<v-list-item :to="`/workers/${item.id}`" v-can="'Ver Novedades'">
							<v-list-item-title>{{ "view_resource" | trans }} {{ "literals.novelty" | trans }}</v-list-item-title>
							<v-list-item-action>
								<v-icon>mdi-eye</v-icon>
							</v-list-item-action>
						</v-list-item>
					</template>
					<template v-slot:extra-item-buttons="{ item }">
						<v-btn
							x-small
							depressed
							class="mr-1"
							color="green"
							dark
							v-can="'Ver Novedades'"
							:to="`/novelties/${item.id}`"
							>{{ "view_resource" | trans }}</v-btn
						>
					</template>
				</crud>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import NoveltyEditor from "./NoveltyEditor";
export default {
	components: { NoveltyEditor },
	methods: {
		checkItem(item) {
			this.api.load("concepts");
			this.item = item;
		},
	},
	data() {
		return {
			item: null,
		};
	},
	watch: {
		"item.concept_id": {
			handler() {
				if (this.item.concept_id) {
					if (this.api.objects.concepts.collection[this.item.concept_id]) {
						const concept = this.api.objects.concepts.collection[this.item.concept_id];
						this.$set(this.item, "description", concept.name);
						this.$set(this.item, "type", concept.type);
						this.$set(this.item, "value_type", concept.value_type);
						this.$set(this.item, "value", concept.default_value || 1);
						this.$set(this.item, "is_salarial", !!concept.is_salarial);
					}
				}
			},
		},
	},
};
</script>

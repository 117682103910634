import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardText,[_c('crud',{attrs:{"model":'Novelty',"create-permission":"Agregar Novedades","edit-permission":"Editar Novedades","delete-permission":"Eliminar Novedades","resource":"novelties","load":"worker,concept,cliente","single-label":_vm._f("trans")('novelty'),"show-clone":"","bulk-actions":""},on:{"add-item":_vm.checkItem,"edit-item":_vm.checkItem},scopedSlots:_vm._u([{key:"form",fn:function({ headers, item, cancelAction, saveAction, getData }){return [_c('novelty-editor',{attrs:{"headeers":headers,"value":item,"save-action":saveAction},on:{"cancel":cancelAction,"saved":getData}})]}},{key:"extra-items-menu",fn:function({ item }){return [_c(VListItem,{directives:[{name:"can",rawName:"v-can",value:('Ver Novedades'),expression:"'Ver Novedades'"}],attrs:{"to":`/workers/${item.id}`}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm._f("trans")("view_resource"))+" "+_vm._s(_vm._f("trans")("literals.novelty")))]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-eye")])],1)],1)]}},{key:"extra-item-buttons",fn:function({ item }){return [_c(VBtn,{directives:[{name:"can",rawName:"v-can",value:('Ver Novedades'),expression:"'Ver Novedades'"}],staticClass:"mr-1",attrs:{"x-small":"","depressed":"","color":"green","dark":"","to":`/novelties/${item.id}`}},[_vm._v(_vm._s(_vm._f("trans")("view_resource")))])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }